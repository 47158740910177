var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MaskView",
    {
      attrs: {
        "border-style": "1px solid #eee",
        "outer-border-style": "1px dashed #02a7f0"
      },
      scopedSlots: _vm._u([
        {
          key: "top",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "vo-row align-items-center vo-gap-4 justify-content-end p-2"
                },
                [
                  _c("el-image", {
                    attrs: {
                      src: require("@/assets/images/brand/ic_eyes.svg")
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("onReviewClick")
                      }
                    }
                  }),
                  _vm.showFillLike == 0
                    ? _c("el-image", {
                        attrs: {
                          src: require("@/assets/images/brand/ic_like.svg")
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("clickFavoriteCurve")
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.showFillLike == 1
                    ? _c("el-image", {
                        attrs: {
                          src: require("@/assets/images/brand/ic_like_fill.svg")
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("clickFavoriteCurve")
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { ref: "historyWrap", staticClass: "vo-column w-100" },
        [
          _c("HistoryCurve", {
            attrs: {
              width: _vm.curveWidth,
              power: _vm.power,
              resistance: 1.2,
              "max-power": 12
            }
          }),
          _c("div", { staticClass: "ml-1 mr-1 mt-2" }, [
            _c("div", [_vm._v(_vm._s(_vm.curveName))])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }