<!--
 * @Author: Code-HHX
 * @Date: 2022-04-26 17:26:58
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-27 15:02:06
 * @Description: 温度同步记录
-->
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <b-row class="vo-items-center">
          <b-col>
            <button
              v-for="(item, index) in btnList"
              :key="index"
              :style="{
                backgroundColor:
                  activeIndex === index ? 'rgba(255, 255, 255, 1)' : '',
                color: activeIndex === index ? '#000000' : '',
              }"
              class="buttonStyle"
              @click="clickBtn(index)"
            >
              {{ item }}
            </button>
          </b-col>
        </b-row>

        <b-card>
          <!-- 查询条件 -->
          <b-row class="vo-items-center">
            <b-col lg="4">
              <div class="vo-row vo-items-center">
                <ReferenceBy label="REFINED BY" />
                <b-input
                  style="flex: 1; margin-left: 10px"
                  v-model="search_condition.curveName"
                  placeholder="Search Voltage Curves Name"
                />
              </div>
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.supplierType"
                :options="types.userTypeList"
              />
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.deviceModelId"
                :options="types.deviceList"
              />
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.brandId"
                :options="types.brandList"
              />
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.cartridgeModelId"
                :options="types.skuList"
              />
            </b-col>
            <b-col>
              <div class="vo-row vo-items-center vo-justify-between">
                <div class="custom-color cursor" @click="clearAll">
                  Clear All
                </div>
                <b-button variant="primary" @click="search">Search</b-button>
              </div>
            </b-col>
          </b-row>

          <div class="table-responsive mb-0">
            <div v-if="isBusy">
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </div>
            <div v-else>
              <b-row
                cols-sm="1"
                cols-md="2"
                cols-lg="3"
                cols-xl="4"
                style="margin: 0"
              >
                <b-col
                  sm="6"
                  v-for="(item, index) in voltage"
                  :key="index"
                  class="mt-4 cursor"
                >
                  <CurveItem
                    :power="item.heatingVoltage"
                    :curveName="item.curveName"
                    :showFillLike="item.isFavorite"
                    @onReviewClick="onReviewClick(item)"
                    @clickFavoriteCurve="clickFavoriteCurve(item)"
                  />
                </b-col>
              </b-row>
            </div>
          </div>

          <b-row v-if="has_data" class="mt-4">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <li>
                    <p
                      class="pagination mb-0"
                      style="margin-top: 7px; margin-right: 20px"
                    >
                      {{ rows }} results
                    </p>
                  </li>
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="8"
                    :total-rows="rows"
                    @change="onPageChange"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <Dialog
      v-model="showAlert"
      :imageSrc="require('@/assets/confirm.svg')"
      content="showContent"
      okBtn="Yes"
      noBtn="No"
      @ok="enableOrDisableCurve"
      @cancel="showAlert = false"
    >
      <p>{{ showContent }}</p>
    </Dialog>

    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      content="Saved successfully"
      noBtn="OK"
      @cancel="successAlear = false"
    >
      <p>Saved successfully</p>
    </Dialog>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import HistoryCurve from "@/components/HistoryCurve.vue";
import ReferenceBy from "@/components/FindByLabel.vue";
import CurveItem from "@/components/curve/CurveItem.vue";
import Dialog from "@/components/Dialog.vue";
/**
 * User component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
    timeZoneConversion,
    HistoryCurve,
    ReferenceBy,
    CurveItem,
    Dialog,
  },
  data() {
    return {
      title: "Voltage Records",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "All Histories",
          // href: "/"
        },
        {
          text: "Voltage Records",
          active: true,
        },
      ],

      search_condition: {
        client: "", //客户名称
        deviceModelId: "", //设备型号
        startTime: "", //开始时间
        endTime: "", //结束时间
        pageNum: 1,
        pageSize: 8,
        supplierId: localStorage.supplierId,
        supplierType: "",
        curveName: "",
        brandId: "",
        cartridgeModelId: "",
        isFavorite: "",
      },
      selectTime: ["", ""],
      types: {
        deviceList: [
          { text: "Available Compatibility", value: "", disabled: false },
          { text: "OMNI NFC", value: "8", disabled: false },
          { text: "OMNI Hub", value: "11", disabled: false },
        ], //适用设备列表
        userTypeList: [
          { text: "User Type", value: "" },
          { text: "Client", value: 2 }, //品牌方数据
          { text: "User", value: 1 }, //客户数据
        ],
        brandList: [{ text: "All Brand", value: "", disabled: false }],
        skuList: [{ text: "All Sku", value: "", disabled: false }],
      },
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 8,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      isBusy: false,
      has_data: false,
      voltage: [],
      btnList: ["History Curves", "Favorite Curves", "Production Curves"],
      activeIndex: 0,
      showAlert: false,
      showContent: "",
      successAlear: false,
    };
  },
  watch: {
    selectTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.selectAllBrand();
      this.selectAllSku();
      this.selectFirmwareSettingRecordList();
    },
    selectFirmwareSettingRecordList() {
      this.isBusy = true;
      return this.$api.Client.selectFirmwareSettingRecordList(
        this.search_condition
      )
        .then((x) => {
          this.isBusy = false;
          x.data.forEach((element) => {
            element.status = "Verified";
            if (element.preheatSetting == 1) {
              element.preheat =
                element.preheatTime + "s , " + element.preheatVoltage + "v";
            } else {
              element.preheat = "-";
            }
            var curve = element.heatingVoltage.split(",");
            var curveData = curve.map((item) =>
              parseFloat((Math.pow(parseInt(item) / 1000, 2) / 1.2).toFixed(2))
            );
            element.heatingVoltage = curveData;
          });
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            this.voltage = x.data;
          } else {
            this.has_data = false;
            this.voltage = [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          this.voltage = [];
        });
    },
    selectAllBrand() {
      this.$api.Dropdown.selectAllBrand()
        .then((x) => {
          this.types.brandList = this.types.brandList.concat(
            x.data.map((item) => {
              return {
                text: item.brandName,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },
    selectAllSku() {
      this.$api.Dropdown.selectAllSku()
        .then((x) => {
          this.types.skuList = this.types.skuList.concat(
            x.data.map((item) => {
              return {
                text: item.deviceName,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },

    onPageChange(page) {
      this.search_condition.pageNum = page;
      this.selectFirmwareSettingRecordList();
    },

    clickBtn(index) {
      this.search_condition.pageNum = 1;
      if (this.activeIndex != index) {
        if (index == 1) {
          this.search_condition.isFavorite = 1;
        } else {
          this.search_condition.isFavorite = "";
        }
        this.selectFirmwareSettingRecordList();
      }
      this.activeIndex = index;
    },

    clearAll() {
      this.search_condition = {
        client: "",
        deviceModelId: "",
        startTime: "",
        endTime: "",
        pageNum: 1,
        pageSize: 8,
        supplierId: localStorage.supplierId,
        supplierType: "",
        curveName: "",
        brandId: "",
        cartridgeModelId: "",
      };
    },
    search() {
      this.search_condition.pageNum = 1;
      this.selectFirmwareSettingRecordList();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onReviewClick(item) {},
    clickFavoriteCurve(item) {
      //禁用或者启用
      let isFavorite = 0;
      if (item.isFavorite == 0) {
        isFavorite = 1;
        this.showContent = "Are sure you want to save this curve?";
      } else {
        this.showContent = "Are sure you want to cancel saving this curve?";
      }
      this.showAlert = true;
      this.postData = {
        id: item.id,
        isFavorite: isFavorite,
      };
    },
    enableOrDisableCurve() {
      this.$api.Client.favoriteCurveHistory(this.postData)
        .then((res) => {
          if (res.success) {
            this.showAlert = false;
            this.successAlear = true;
            this.selectFirmwareSettingRecordList();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((res) => {
          this.$bvToast.toast(res.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.temperature {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonStyle {
  width: 173px;
  height: 51px;
  border: 1px solid #d3d3d3;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
  background-color: rgba(2, 167, 240, 1);
  margin-left: 2px;
}
</style>

